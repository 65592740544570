<template>
  <master-layout
    v-if="ordineDiServizio"
    :smallTitle="ordineDiServizio.Dossier"
    titleStyle="horizontal"
    :subtitle="ordineDiServizio.titolo"
    @master-layout-back="MasterLayoutBack"
  >
    <div v-if="loadingOrdineDiServizio">Caricamento in corso...</div>
    <div
      v-else-if="ordineDiServizio"
      style="padding-bottom: 100px; padding-top: 60px"
    >
      <div id="infoServizio">
        <CardCollapsable class="ion-margin-bottom" status="collapsed">
          <template #title>
            <span class="font-bold">Disposizioni di Servizio</span>
          </template>
          <template #body>
            <div class="flex flex-column">
              <span class="text-14 font-bold mb-4">{{
                ordineDiServizio.titolo
              }}</span>

              <button
                v-if="ordineDiServizio.allegato"
                class="p-d button-primary mb-2"
                @click="openFileAllegato"
              >
                Apri file allegato
              </button>

              <div
                :innerHTML="
                  ordineDiServizio.descrizione || ordineDiServizio.annotazioni
                "
              ></div>

              <span class="text-color-light"
                ><span class="font-bold">Dossier: </span
                >{{ ordineDiServizio.Dossier }}</span
              >
              <span class="text-color-light"
                ><span class="font-bold">Cliente: </span
                >{{ ordineDiServizio.Cliente }}</span
              >

              <span class="text-color-light"
                ><span class="font-bold">Indagato: </span
                >{{ ordineDiServizio.indagato }}</span
              >
            </div>
          </template>
          <template #footer>
            <div class="flex w-full justify-content-between">
              <div class="flex flex-column">
                <span class="text-18 font-bold">INIZIO</span>
                <span class="text-12">{{
                  new Date(ordineDiServizio.dataOra_inizio).toLocaleDateString(
                    "it"
                  )
                }}</span>
              </div>
              <span class="text-12 text-color-secondary">
                <a
                  target="_blank"
                  :href="ordineDiServizio.linkMaps"
                  class="text-button flex align-items-center text-color-primary"
                >
                  MAPPA
                  <ion-icon
                    class="icon-rounded bg-secondary ml-2"
                    :icon="pin"
                  ></ion-icon>
                </a>
              </span>
            </div>
          </template>
        </CardCollapsable>

        <CardCollapsable class="ion-margin-bottom" :footerVisible="false">
          <template #title>
            <span class="font-bold">Informazioni Dossier</span>
          </template>
          <template #body>
            <div v-html="ordineDiServizio.descrizioneDossier"></div>

            <CardCollapsable
              :backgroundLight="false"
              :footerVisible="false"
              :withPadding="false"
              class="my-2"
            >
              <template #title>
                <span class="text-14">Messaggi condivisi</span>
              </template>
              <template #body>
                <div v-for="elemento in messaggiCondivisi" :key="elemento.id">
                  <div
                    v-if="currentUser"
                    class="elemento axerta-messaggio-condiviso axerta-tag ion-margin-top ion-margin-bottom ion-padding relative"
                    :class="{
                      'type-u': elemento.userType == 'U',
                      'type-b': elemento.userType == 'B',
                      'is-user': elemento.id_user == id_logged_user,
                      'is-not-user': elemento.id_user != id_logged_user,
                    }"
                  >
                    <div
                      class="flex justify-content-between align-items-center"
                    >
                      <div>
                        <span>{{ elemento.message }}</span>
                        <div v-if="elemento.files.length > 0">
                          <img
                            v-for="(image, i) in elemento.files"
                            :key="i"
                            :src="elemento.files[i].url"
                            @error="handleImgError"
                          />
                        </div>
                      </div>
                      <div
                        v-if="elemento.userImage"
                        class="avatar-xs absolute top--10 right--10"
                      >
                        <img :src="elemento.userImage" />
                      </div>
                      <div v-else class="avatar-xs absolute top--10 right--10">
                        <img
                          src="../../../public/assets/img/avatar_placeholder.png"
                          @error="handleImgError"
                        />
                      </div>
                    </div>
                    <div class="firma-messaggio mt-2">
                      <span>{{
                        new Date(elemento.data).toLocaleDateString("it", {
                          year: "2-digit",
                          month: "2-digit",
                          day: "2-digit",
                        })
                      }}</span>

                      <span v-if="elemento.user" class="ml-2">
                        {{ elemento.user }}</span
                      >
                    </div>
                  </div>
                </div>
              </template>
            </CardCollapsable>

            <CardCollapsable
              v-for="elemento in ordineDiServizio.elementi"
              :key="elemento.id"
              :backgroundLight="false"
              :footerVisible="false"
              :withPadding="false"
              class="my-3"
            >
              <template #title>
                <span class="text-14">{{ elemento.tag }}</span>
              </template>
              <template #body>
                <div
                  class="elemento-axerta-descrizione"
                  v-if="elemento.descrizione"
                >
                  {{ elemento.descrizione }}
                </div>
                <div class="elemento-axerta-link" v-if="elemento.link">
                  <a
                    v-if="isGeoLink(elemento.link)"
                    target="_blank"
                    :href="elemento.link"
                    >Guarda sulla mappa</a
                  >
                </div>
                <div v-if="elemento.allegati.length > 0">
                  <img
                    v-if="isImgUrl(elemento.allegati[0].url)"
                    :src="elemento.allegati[0].url"
                    @error="handleImgError"
                  />

                  <iframe
                    v-else-if="elemento.allegati[0].url.includes('.pdf')"
                    :src="elemento.allegati[0].url"
                    frameborder="0"
                  ></iframe>
                  <a v-else :href="elemento.allegati[0].url">Apri documento</a>
                </div>
              </template>
            </CardCollapsable>
          </template>
        </CardCollapsable>

        <CardCollapsable
          class="ion-margin-bottom"
          :footerVisible="false"
          status="collapsed"
        >
          <template #title>
            <span class="font-bold">Interventi effettuati</span>
          </template>
          <template #body>
            <div v-for="intervento in rilevazioni" :key="intervento.id">
              <div class="riga">
                <div class="col-text">
                  {{
                    new Date(intervento.dataOraInizio).toLocaleDateString("it")
                  }}
                  {{
                    new Date(intervento.dataOraInizio).toLocaleTimeString(
                      "it",
                      {
                        hour: "2-digit",
                        minute: "2-digit",
                      }
                    )
                  }}
                  - {{ intervento.luogoInizio }}
                </div>
                <div class="col-icon">
                  <ion-icon
                    @click="gotoElencoRilevazioni(intervento)"
                    v-if="intervento.dataOraFine"
                    :icon="cloudUploadOutline"
                    style="font-size: 1.7rem"
                    class="camera-icon"
                  ></ion-icon>
                  <ion-icon
                    @click="gotoElencoRilevazioni(intervento)"
                    v-else
                    :icon="glassesOutline"
                    style="font-size: 1.7rem"
                    class="camera-icon"
                  ></ion-icon>
                </div>
              </div>
            </div>
          </template>
        </CardCollapsable>
      </div>
    </div>

    <div>
      <button
        v-if="rilevazioneInCorso"
        @click="gotoFinisciRilevazione"
        class="fixed bottom left button-primary w-40 text-16"
      >
        Termina Servizio
      </button>
      <button
        v-else-if="isValidTime"
        @click="gotoIniziaRilevazione"
        class="fixed bottom left button-primary w-47 text-16"
      >
        <span class="text-client-color-text-button">Inizia Servizio</span>
      </button>

      <!-- <button
        @click="openModalChat"
        class="fixed bottom right button-secondary w-47 text-16"
      >
        <ion-icon
          :icon="chatbubble"
          class="mr-1"
        ></ion-icon>
        <span class="text-client-color-text-button">Dossier Manager</span>
      </button> -->
    </div>
  </master-layout>
</template>

<script setup>
import { ref, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import ModalChat from "../Modals/AxertaChat.vue";
import {
  IonRow,
  IonCol,
  IonButton,
  IonIcon,
  IonCard,
  modalController,
} from "@ionic/vue";
import {
  chevronDownOutline,
  chevronUpOutline,
  cloudUploadOutline,
  glassesOutline,
  toggle,
  pin,
  chatbubble,
} from "ionicons/icons";

import ApiService from "../../common/service.api";
import CardCollapsable from "@/components/Nuovi/CardCollapsable.vue";

const router = useRouter();
const route = useRoute();
const store = useStore();

const expanded = ref({
  infoServizio: true,
  disposizioni: true,
  infoDossier: false,
  rilevazioni: false,
});

const id_logged_user = ref(store.getters["user/getUserData"].id);
const currentUser = ref(store.getters["user/getUserData"]);

const isValidTime = computed(() => {
  const { dataOra_inizio, durataStimata } = ordineDiServizio.value;

  const dataOraInizio = new Date(dataOra_inizio);
  const durataInMillisecondi = durataStimata * 24 * 60 * 60 * 1000;
  const dataOraFine = new Date(dataOraInizio.getTime() + durataInMillisecondi);
  const now = new Date();

  console.log("is valid time ", {
    dataOraInizio: dataOraInizio,

    durataStimata: durataStimata,

    dataOraFine: dataOraFine,

    now: now,
    dataOraInizioTIME: dataOraInizio.getTime(),
    dataOraFineTIME: dataOraFine.getTime(),
    nowTIME: now.getTime(),
  });

  return (
    now.getTime() > dataOraInizio.getTime() &&
    now.getTime() < dataOraFine.getTime()
  );
});

function openFileAllegato() {
  if (ordineDiServizio.value.allegato) {
    window.open(ordineDiServizio.value.allegato, "_blank");
  }
}

function isGoogleMapsAppLink(str) {
  return str.includes("https://maps.app.goo");
}

const ordineDiServizio = ref();
const loadingOrdineDiServizio = ref(false);
function getOrdineDiServizio() {
  loadingOrdineDiServizio.value = true;
  ApiService.get("DossierManager/GetOrdineDiServizio/" + route.params.idOrdine)
    .then((res) => {
      res.data.elementi.forEach((element) => {
        element.visible = false;
      });

      ordineDiServizio.value = res.data;
      console.log(
        "🚀 ~ file: DescrizioneOrdine.vue:299 ~ .then ~ res.data:",
        res.data
      );
    })
    .finally(() => {
      loadingOrdineDiServizio.value = false;
      // getMessaggiCondivisi()
      createWidget();
    });
}
getOrdineDiServizio();

function handleImgError() {
  return "https://placehold.co/300x300?text=MartechRevolution";
}

function isImgUrl(url) {
  return /\.(jpg|jpeg|png|webp|avif|gif)$/.test(url);
}

const messaggiCondivisiVisible = ref(false);
function toggleMessaggiCondivisiVisible() {
  messaggiCondivisiVisible.value = !messaggiCondivisiVisible.value;
}

function expandItem(string) {
  expanded.value[string] = !expanded.value[string];
  if (expanded.value[string] == true) {
    setTimeout(() => {
      document
        .querySelector("#" + string)
        .lastChild.scrollIntoView({ behavior: "smooth" });
    }, 500);
  }
}

const rilevazioni = ref([]);
const rilevazioneInCorso = ref();
async function getRilevazioni() {
  ApiService.get("DossierManager/GetRilevazioni/" + route.params.idOrdine).then(
    (res) => {
      rilevazioni.value = res.data;
      rilevazioni.value.forEach((rilevazione) => {
        if (rilevazione.dataOraFine) {
          rilevazioneInCorso.value = null;
        } else {
          rilevazioneInCorso.value = rilevazione.id;
        }
      });
    }
  );
}

function loadBandyerSDK() {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.src =
      "https://cdn.bandyer.com/sdk/js/chat/2.5.5/bandyer-widget.min.js";
    script.async = true;
    script.onload = resolve;
    script.onerror = reject;
    document.body.appendChild(script);
  });
}

const idUtenteK = ref(null);
const Client = ref(null);

async function createWidget() {
  console.log("create widget ");
  const userId = await getUserId();
  // const userId = "durante_AxEmmanuele_48"
  const appId =
    "wAppId_afdc43bb4e41128b379a6bb5f161b82ccb03c81cda43b236a9f36ad2acae";
  const region = "eu";
  const environment = "sandbox"; //note the sandbox environment exists only in eu region

  const retrieveAccessToken = async (region, environment, userId) => {
    // retrieve the access token using POST /sdk/credentials on the server side
    const accessToken = await ApiService.get(
      `VideoChat/GetToken/${userId}`
    ).then((res) => {
      return res.data;
    });
    return accessToken;
  };

  try {
    console.log(
      "🚀 ~ file: DescrizioneOrdine.vue:442 ~ createWidget ~ region, environment, userId:",
      region,
      environment,
      userId
    );
    await loadBandyerSDK();
    Client.value = window.BandyerSDK.configure({
      appId: appId,
      // userId: userId,
      region: region,
      environment: environment,
      tools: {
        chat: true,
      },
    });

    Client.value.on("client:access_token:is_about_to_expire", async (data) => {
      const accessToken = await retrieveAccessToken(
        region,
        environment,
        userId
      );
      const { expiresAt } = await Client.value.updateAccessToken(accessToken);
    });

    Client.value.on("client:access_token:expired", async () => {
      await Client.value.disconnect();
      const accessToken = await retrieveAccessToken(
        region,
        environment,
        userId
      );
      await Client.value.connect(userId, accessToken);
    });

    const accessToken = await retrieveAccessToken(region, environment, userId);

    await Client.value.connect(userId, accessToken);
  } catch (error) {
    console.error("Failed to retrieve the access token or connect:", error);
  }
}

async function getUserId() {
  console.log(
    "get user id ",
    ordineDiServizio.value.idDossier,
    id_logged_user.value
  );

  try {
    const res = await ApiService.get(
      `DossierManager/GetChatDossier/${ordineDiServizio.value.idDossier}/${id_logged_user.value}`
    );
    console.log("🚀 ~ file: DescrizioneOrdine.vue:442 ~ getUserId ~ res:", res);
    idUtenteK.value = res.data.idCieChat;
    return res.data.idCieChat;
  } catch {
    console.log("error");
  }
}

async function openModalChat() {
  const modal = await modalController.create({
    component: ModalChat,
    cssClass: "common-modal",
    componentProps: {
      item: { ...ordineDiServizio.value },
    },
  });
  await modal.present();

  let modalResponse = await modal.onDidDismiss();

  modalResponse.data && this.modalCloseHandler({ ...modalResponse.data });
}

function toggleElementoVisible(elemento) {
  elemento.visible = !elemento.visible;
}

function isGeoLink(link) {
  return link.includes("goo.gl/maps");
}

const messaggiCondivisi = ref();
function getMessaggiCondivisi() {
  ApiService.get(
    "DossierManager/GetSharedMessages/" + ordineDiServizio.value.idDossier
  ).then((res) => {
    messaggiCondivisi.value = res.data;
  });
}

function gotoElencoRilevazioni(intervento) {
  console.log("goto elenco rilevazioni ", Client);
  if (Client.value.userId) {
    Client.value.hideWidget();
  }
  router.push(
    // "/axerta/elenco-rilevazioni/" + route.params.idOrdine + "/" + intervento.id + '/',
    {
      name: "ElencoRilevazioni",
      params: {
        idOrdine: route.params.idOrdine,
        idIntervento: intervento.id,
        inizioIntervento: intervento.dataOraInizio,
        fineIntervento: intervento.dataOraFine
          ? intervento.dataOraFine
          : "in-corso",
      },
    }
  );
}

function gotoIniziaRilevazione() {
  if (Client.value.userId) {
    Client.value.hideWidget();
  }
  router.push({
    name: "IniziaRilevazione",
    params: {
      idOrdine: route.params.idOrdine,
    },
  });
}

function gotoFinisciRilevazione() {
  if (Client.value.userId) {
    Client.value.hideWidget();
  }
  router.push({
    name: "FinisciRilevazione",
    params: {
      idOrdine: route.params.idOrdine,
      idRilevazione: rilevazioneInCorso.value,
    },
  });
}

function MasterLayoutBack() {
  window.BandyerSDK.destroyClient();
  router.push("/axerta/lista-ordini");
}

getRilevazioni();
</script>

<style lang="scss" scoped>
.totale {
  background-color: white;
  padding: 0 1.5rem;
  z-index: 1;

  font-size: 1.1rem;

  span {
    font-weight: bold;
  }

  position: fixed;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
}

ion-button.red-button::part(native) {
  background-color: maroon;
}

ion-button::part(native) {
  background-color: var(--ion-color-primary);
  color: var(--text-color-light);
  font-size: 1.1rem !important;
  text-transform: none;
  border-radius: var(--ion-border-radius);
  padding: 0.6rem 2rem;
}

ion-button::part(native):hover {
  background-color: var(--ion-color-primary);
  color: var(--text-color-light);
  font-size: 1.1rem !important;
  text-transform: none;
  border-radius: var(--ion-border-radius);
  transform: translateY(2px);
}

ion-button::part(native):active {
  background-color: var(--ion-color-secondary);
  color: var(--ion-color-primary);
  transform: translateY(2px);
}

.riga {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1.5rem;
  border-bottom: 1px solid grey;
}

img {
  border-radius: 6px;
}

.axerta-messaggio-condiviso {
  border: 2px solid rgb(238, 238, 238);
  background: rgb(248, 248, 248);
  color: rgb(0, 0, 0);
  padding: 60px 2rem 1rem;

  display: flex;
  flex-direction: column;

  .firma-messaggio {
    position: absolute;

    display: flex;
    align-content: center;
    justify-content: flex-end;

    font-size: 0.8rem;
    color: gray;
  }
}

.type-u {
  border: 3px solid var(--ion-color-primary);
}

.type-b {
  border: 3px solid var(--ion-color-secondary);
}

.elemento-axerta {
  border: 1px solid rgb(216, 216, 216);
  border-radius: 6px;
  padding: 1rem;
  margin-bottom: 1rem;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06),
    0px 2px 6px rgba(0, 0, 0, 0.12);

  &-tag {
    font-size: 1.1rem;
    color: rgb(70, 70, 70);
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  &-descrizione {
    color: gray;
    margin-bottom: 0.3rem;
  }
}

.cursor-pointer:hover {
  cursor: pointer;
}

.refresh-box {
  width: 35px;
  height: 47.59px;
  display: grid;
  padding-left: 1rem;
  place-content: center;
}

.is-user {
  position: relative;
  border-radius: 25px 25px 0 25px;

  .avatar-xs {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .firma-messaggio {
    top: 10px;
    right: 58px;
  }
  // border: 3px solid greenyellow !important;
}

.is-not-user {
  position: relative;
  border-radius: 25px 25px 25px 0;
  .avatar-xs {
    position: absolute;
    top: 10px;
    left: 10px;
  }

  .firma-messaggio {
    top: 10px;
    left: 58px;
  }
  // border: 3px solid purple !important;
}
</style>
